/*
  NOTE: The order of imports is important.
*/

@tailwind base;

// Custom Compoent
@tailwind components;
@import "./scrollbar";

// overrides and globals should be after themes.
@import "./overrides";
@import "./globals";

// utils and helpers should be after everything
@tailwind utilities;
