.title-pic {
  max-width: 550px;
  transform: rotate(10deg) translateX(-50%);
  // @include media-breakpoint-below(sm) {
  //   max-width: 80%;
  // }
}

.scrolldown {
  animation: scrolldown 2s ease-out infinite;
}

@keyframes scrolldown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(200%);
  }
}

.txt-inherit {
  font-size: inherit;
}

.after-clear::after {
  content: none !important;
}

.before-clear::before {
  content: none !important;
}

.text-stroke-primary {
  -webkit-text-stroke: #805ad5 0.03em !important;
  text-stroke: #805ad5 0.03em !important;
}

.text-stroke-white {
  -webkit-text-stroke: #fff 0.03em !important;
  text-stroke: #fff 0.03em !important;
}
