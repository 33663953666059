img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}

label {
  display: block;
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
}

:root,
body {
  font-size: 14px;
}

table {
  width: 100%;
}

.hoverable {
  transition:
    box-shadow 0.3s ease-in-out,
    border-color 0.3s ease-in-out !important;
  @apply hover:border-transparent hover:shadow-md;
}

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.icon {
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 1em;
  height: 1em;
}

.icon svg {
  vertical-align: baseline;
}

.is-min {
  background-color: transparent !important;
  @apply before:content-[""] relative rounded-none;

  &::before {
    @apply absolute bottom-0 left-0 right-0 origin-right scale-x-0 border-b-2 border-primary transition-transform duration-500;
  }

  &:hover {
    @apply before:origin-left before:scale-x-100;
  }
}
