//
// Scollbar
//

//
// Inherit
//
$scrollbar-thumb-radius: 50em !default;

//
// Setting
//
$scrollbar-width: 16px !default;
$scrollbar-height: 16px !default;
$scrollbar-background: white !default;
$scrollbar-padding: 4px !default;
$scrollbar-hover-background: rgb(206, 212, 218) !default;

$scrollbar-thumb-background: rgba(rgb(232, 236, 240), 1) !default;
$scrollbar-thumb-focus-background: #e9ecef !default;

//
// Note : Experimental Feature
// Issue : Doesn't work on Internet Explorer, Firefox, Edge 44
// Doc : add doc
//
.scrollbar {
  &::-webkit-scrollbar {
    @apply h-4 w-4 bg-white dark:bg-neutral-900;
    box-shadow: -2px 0 6px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full border-4 border-solid border-white bg-slate-200 dark:border-neutral-900 dark:bg-slate-600;
  }

  // stylelint-disable-next-line selector-pseudo-class-disallowed-list
  &::-webkit-scrollbar-thumb:focus {
    @apply bg-slate-200 dark:bg-slate-600;
  }
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-slate-300 dark:bg-slate-500;
  }
}
