.label {
  @apply block;
}

.react-flow__attribution {
  display: none;
}

.react-flow__node.selectable.selected {
  z-index: 20 !important;
}
